<template>
	<section class="user-edit-view">
		<h3 v-if="user.id">User Edit</h3>
		<h3 v-else>Invite User</h3>
		<div v-if="error" class="roundup-alert roundup-alert-error">
			{{ error }}
		</div>
		<form v-on:submit.prevent="submit">
			<ui-label>
				<ui-input
					v-model="user.first_name"
					placeholder="First Name"
				></ui-input>
			</ui-label>
			<ui-label>
				<ui-input
					v-model="user.last_name"
					placeholder="Last Name"
				></ui-input>
			</ui-label>
			<ui-label>
				<ui-input v-model="user.email" placeholder="Email"></ui-input>
			</ui-label>
			<ui-label>
				<ui-input v-model="user.phone" placeholder="Phone"></ui-input>
			</ui-label>
			<ui-label
				v-if="role <= 1"
				v-bind:style="{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center'
				}"
			>
				<ui-toggle
					v-bind:style="{ flex: 1 }"
					@input="checked(user.role_id)"
					:checked="user.role_id === 1"
					:disabled="user.email === me.email"
				>
				</ui-toggle>
				<p v-bind:style="{ flex: 5 }">
					<strong>Admin/Developer Access:</strong> This will allow the
					user to rotate API keys.
				</p>
			</ui-label>
			<ui-button
				style="background: #2553CD"
				native-type="submit"
				:loading="isSaving"
				>{{ user.id ? 'Save' : 'Invite' }}</ui-button
			>
			<ui-button
				type="danger"
				v-if="user.id && user.email !== me.email && me.role_id < 2"
				@click="remove"
				style="float: right"
				>Delete</ui-button
			>
		</form>
	</section>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import { isEmpty } from '@/utilities'

import UiButton from '../../ui/Button'
import UiLabel from '../../ui/Label'
import UiInput from '../../ui/Input'
import UiToggle from '../../ui/Toggle'

export default {
	name: 'user-edit-view',
	inject: ['modalOptions'],
	components: { UiButton, UiLabel, UiInput, UiToggle },
	data() {
		return {
			user: cloneDeep(this.$store.state.userDetail.item),
			isSaving: false,
			error: null
		}
	},
	computed: {
		...mapGetters(['role', 'me'])
	},
	methods: {
		...mapActions(['updateUser', 'createUser', 'removeUser']),
		submit() {
			this.user.id ? this.update() : this.create()
		},
		checked(role) {
			// the initial role is undefined so just defaulting to 2 since that is what it should be.
			role = role || 2
			this.user.role_id = role === 2 ? 1 : 2
		},
		create() {
			this.isSaving = true
			this.createUser({ ...this.user })
				.then(user => {
					this.isSaving = false
					this.modalOptions.modalOpen = false
					this.$swal({
						title: 'User Invited',
						text:
							'They will receive an email shortly and be asked to create an account to complete this step.',
						showCancelButton: false,
						allowOutsideClick: false,
						allowEscapeKey: false
					}).then(() => {
						this.navigate({ name: 'api' })
					})
				})
				.catch(({ message }) => {
					this.isSaving = false
					this.error = message
				})

			return false
		},
		update() {
			this.isSaving = true
			this.updateUser(this.user)
				.then(() => {
					this.isSaving = false
					this.modalOptions.modalOpen = false
				})
				.catch(({ message }) => {
					this.isSaving = false
					this.error = message
				})

			return false
		},
		remove() {
			if (
				confirm(
					'Are you sure you want to remove this user? This action cannot be undone.'
				)
			) {
				this.removeUser(this.user).then(() => {
					this.modalOptions.modalOpen = false
				})
			}

			return false
		},
		isEmpty
	}
}
</script>
