<template>
	<div :class="[size ? 'loading--' + size : 'loading']" v-if="loading" />
</template>

<script>
export default {
	name: 'roundup-loader',
	props: ['loading', 'size']
}
</script>

<style lang="scss">
@import '@roundupapp/component-library/src/styles/index';
.loading {
	padding-right: 55px;
	&:before {
		content: '';
		position: absolute;
		display: block;
		width: 0px;
		height: 0px;
		border-radius: 50%;
		right: 15px;
		top: 25px;
		border: 2px solid rgba(255, 255, 255, 0.8);
		border-right: 2px solid $roundup-primary;
		animation: rotate360 0.5s infinite linear, exist 0.1s forwards ease;
	}
}
.loading--big {
	padding-right: 55px;
	&:before {
		content: '';
		position: absolute;
		display: block;
		width: 0px;
		height: 0px;
		border-radius: 50%;
		right: 15px;
		top: 25px;
		border: 2px solid rgba(255, 255, 255, 0.8);
		border-right: 2px solid $roundup-primary;
		animation: rotate360 0.5s infinite linear, existBig 0.1s forwards ease;
	}
}

@keyframes rotate360 {
	100% {
		transform: rotate(360deg);
	}
}
@keyframes exist {
	100% {
		width: 12px;
		height: 12px;
		margin: -8px 5px 0 0;
	}
}
@keyframes existBig {
	100% {
		width: 50px;
		height: 50px;
		margin: -8px 5px 0 0;
	}
}
</style>
