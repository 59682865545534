<template>
	<div class="user-list-view">
		<div
			:style="{
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-between',
				width: '100%'
			}"
		>
			<h1 style="font-size: 1.5rem">Your Team</h1>
			<ui-button
				:style="{
					minWidth: '250px',
					margin: 'auto',
					marginRight: '0px'
				}"
				@click="addUser"
				>Add New Team Member</ui-button
			>
		</div>
		<div
			v-if="error"
			class="roundup-alert roundup-alert-error"
			:style="{ marginBottom: '25px' }"
		>
			{{ error }}
		</div>
		<div
			v-if="success"
			class="roundup-alert roundup-alert-info"
			:style="{ marginBottom: '25px' }"
		>
			{{ success }}
		</div>
		<el-table slot="table" :data="items" style="width: 100%">
			<el-table-column prop="first_name" label="First Name" width="180">
			</el-table-column>
			<el-table-column prop="last_name" label="Last Name" width="180">
			</el-table-column>
			<el-table-column prop="email" label="Email"> </el-table-column>
			<el-table-column prop="invite_code" label="State">
				<template slot-scope="scope">
					{{ isEmpty(scope.row.invite_code) ? 'Active' : 'Pending' }}
				</template>
			</el-table-column>
			<el-table-column label="Actions">
				<template slot-scope="scope">
					<el-button
						size="mini"
						@click="navigateToUserDetail(scope.row)"
						>Edit</el-button
					>
					<el-button
						v-if="!isEmpty(scope.row.invite_code)"
						size="mini"
						:disabled="isSaving"
						@click="onResendInvite(scope.row)"
						>Resend Invite</el-button
					>
					<ui-loader :loading="isSaving" />
				</template>
			</el-table-column>
			<el-table-column label="Administrator" v-if="role <= 1">
				<template slot-scope="scope">
					<ui-toggle
						v-bind:style="{ flex: 1 }"
						@input="developerChecked(scope.row)"
						:checked="scope.row.role_id === 1"
						:disabled="scope.row.email === me.email"
					>
					</ui-toggle>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination
			@current-change="pageChange"
			:current-page="page"
			:page-size="perPage"
			layout="prev, pager, next, total"
			:total="total"
		>
		</el-pagination>

		<modal v-model="showUser" size="small">
			<user-edit-view></user-edit-view>
		</modal>
	</div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { get, isEmpty } from '@/utilities'
import table from '@/mixins/table'

import UiButton from '../../ui/Button'
import UiLoader from '../../ui/Loader'
import UiToggle from '../../ui/Toggle'
import Screen from '../../ui/Screen'
import Modal from '../../ui/Modal'
import UserEditView from './UserEditView'

// TODO finish edit + resend invite + developers

export default {
	name: 'user-list-view',
	components: { UiButton, Screen, Modal, UserEditView, UiLoader, UiToggle },
	mixins: [table],
	computed: {
		...mapState({
			items: ({ userList }) => userList.items,
			total: ({ userList }) => userList.total
		}),
		...mapGetters(['role', 'me'])
	},
	data() {
		return {
			showUser: false,
			error: null,
			isSaving: false,
			success: null,
			inviteCode: null
		}
	},
	methods: {
		...mapActions(['getUsers', 'resendInvite', 'updateUser']),
		navigateToDonorDetail(donor) {
			this.$router.push({
				name: 'donors.detail',
				params: { donorId: donor.id }
			})
		},
		paramsChanged(params) {
			this.getUsers(params)
		},
		addUser() {
			this.$store.commit('USER_DETAIL', { id: null })
			this.showUser = true
		},
		navigateToUserDetail(user) {
			this.$store.commit('USER_DETAIL', user)
			this.showUser = true
		},
		get,
		isEmpty,
		onResendInvite(admin) {
			this.isSaving = true
			this.inviteCode = admin.invite_code
			this.resendInvite(admin)
				.then(() => {
					this.isSaving = false
					this.success = 'Your invite has been resent.'
					setTimeout(() => {
						this.success = null
					}, 2000)
				})
				.catch(({ message }) => {
					this.isSaving = false
					this.error = message
				})
			return false
		},
		developerChecked(scope) {
			this.isSaving = true
			const user = scope
			user.role_id = scope.role_id === 2 ? 1 : 2
			this.updateUser(user)
				.then(() => {
					this.isSaving = false
				})
				.catch(({ message }) => {
					this.isSaving = false
					this.error = message
				})

			return false
		}
	},
	mounted() {
		this.getUsers(this.getParameters())
	}
}
</script>

<style lang="scss">
@import '@roundupapp/component-library/src/styles/index';
.user-list-view {
	th {
		.cell {
			font-weight: 600;
		}
	}
}
</style>
